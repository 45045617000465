@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* width */
::-webkit-scrollbar {
  width: 0.5rem;
  height:0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #171717;
}

/* Corner */
::-webkit-scrollbar-corner {
  background: #171717;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 0.5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-thumb:active {
  background: #444;
}


/* For Code Highlighting */
.highlighted-line {
  background: yellow;
	width: 0.5rem !important;
	margin-left: 0.5rem;
}