/* CustomStyles.css */

.FolderTree .TreeNode {
  display: flex;
  align-items: center; /* Aligns items vertically in the middle */
  /* Other styles... */
}

/* Increase space between icon and filename */
.FolderTree .TreeNode .iconContainer {
  padding-right: 0 px; /* Adjust this value to increase/decrease space */
  padding-left: 0.25rem; /*Adjust this value to increase/decrease space */
  display: flex; /* */
  align-items: center; /*Aligns items vertically in the middle*/
}

.TreeNode {
  display: flex;
  /* border: 1px solid red; */
  padding-left: 0.25rem;
  padding-right: 0.5rem;
  /* other styles */
}

.TreeNode:hover {
  background-color: #5c5f64;
  border-radius: 2px;
}

/* Change text size and color */
.FolderTree .EditableName .displayName {
  font-size: 13px; /* Adjust this value to increase/decrease text size */
  color: #a5a5a5;
  /* color: red; */
  white-space: nowrap;
  overflow: auto;
  text-overflow: ellipsis;
}
.knowledgebase .FolderTree .EditableName .displayName {
  color: #10b981; /* e.g., color: red; */
}

.FolderTree .EditableName .displayName:hover {
  font-size: 13px; /* Adjust this value to increase/decrease text size */
  /* color:#26947a; */
  cursor: pointer;
  white-space: nowrap;
  overflow: auto;
  text-overflow: ellipsis;
}

/* Change icon color */
/* 
.FolderTree .TreeNode .iconContainer svg {
  fill: white; 
} */

.FolderTree .CheckBox {
  /* background-color: #26947a; */
  filter: brightness(120%) hue-rotate(295deg) saturate(100%);
  display: flex;
  align-items: center;
  /* background-color: aqua; */
  /*Change to the degree value that gives you the green color you want
  /* filter: invert(40%) sepia(53%) saturate(1434%) hue-rotate(101deg) brightness(85%) contrast(90%); */
}

.FolderTree {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

/* For Code Highlighting */
.highlighted-line {
  background: yellow;
  width: 0.5rem !important;
  margin-left: 0.5rem;
}

.checkbox-input:checked {
  filter: brightness(120%) hue-rotate(295deg) saturate(100%);
}
